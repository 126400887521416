'use strict';
import { ILogService } from "angular";
import { StatusGrid, EVehicleStatus, Vehicle } from "../data/vehicles.data";
import RestService from "./rest.service";

export default class VehicleService {
    private $log: ILogService;
    private restService: RestService;
    private statusColorMapping: Map<EVehicleStatus, string>;
    private statusTextColorMapping: Map<EVehicleStatus, string>;
    private statusTranslationMapping: Map<EVehicleStatus, string>;

    constructor($log: ILogService, restService: RestService) {
        this.$log = $log;
        this.restService = restService;
    }

    /**
     * Get all groups for the status tableau
     */
    getGroupsForTableau(forceUpdate: boolean): Promise<Array<string>> {
        return this.restService.getGroupsForTableau(forceUpdate);
    }


    /**
     * Schedules a pending reset timer task immediately (e.g. to reset STATUS_5 immedidately)
     * @param vehicle 
     */
    schedulePendingResetTimerTask(vehicle: Vehicle): Promise<boolean> {
        return this.restService.schedulePendingResetTimerTask(vehicle);
    }

    /**
     * Load the grid for a given group
     * @param group 
     */
    getGridForGroup(group: string): Promise<StatusGrid> {
        return this.restService.getGridForGroup(group);
    };

    getStatusColorMapping(): Promise<Map<EVehicleStatus, string>> {
        return new Promise<Map<EVehicleStatus, string>>((resolve) => {
            if (this.statusColorMapping === undefined) {
                this.restService.getStatusColorMapping().then(data => {
                    this.statusColorMapping = data;
                    resolve(this.statusColorMapping);
                })
            } else {
                resolve(this.statusColorMapping);
            }
        });
    }

    getStatusTranslationMapping(): Promise<Map<EVehicleStatus, string>> {
        return new Promise<Map<EVehicleStatus, string>>((resolve) => {
            if (this.statusTranslationMapping === undefined) {
                this.restService.getStatusTranslationMapping().then(data => {
                    this.statusTranslationMapping = data;
                    resolve(this.statusTranslationMapping);
                })
            } else {
                resolve(this.statusTranslationMapping);
            }
        });
    }

    getStatusTextColorMapping(): Promise<Map<EVehicleStatus, string>> {
        return new Promise<Map<EVehicleStatus, string>>((resolve) => {
            if (this.statusTextColorMapping === undefined) {
                this.restService.getStatusTextColorMapping().then(data => {
                    this.statusTextColorMapping = data;
                    resolve(this.statusTextColorMapping);
                })
            } else {
                resolve(this.statusTextColorMapping);
            }
        });
    }
}